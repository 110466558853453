import * as React from 'react';
import { MainLayout } from 'components/MainLayout';
import { Footer } from 'components/Footer';
import CardIconComponent from 'components/icons/Card';
import OnlineCardIcon from 'components/icons/OnlineCard';
import CashIcon from 'components/icons/Cash';
import MasternCardIcon from 'components/icons/MasterCard';
import VisaCardIcon from 'components/icons/VisaCard';
import MirCardIcon from 'components/icons/MirCard';

const PaymentPage = () => (
  <MainLayout>
    <div className="container mt-10 lg:px-4 lg:mt-0">
      <p className="text-[40px] py-10">Оплата</p>
      <p className="text-lg">Вы можете оплатить заказ любым удобным способом:</p>

      <div className="flex justify-between items-center mt-[30px] lg:flex-wrap">
        <div className="flex items-center justify-center bg-[#F8F8F8] py-[18px] px-2 w-full rounded lg:mb-3 sm:justify-start">
          <CashIcon size={26} className="flex-none" />
          <p className="text-lg ml-[20px] font-medium whitespace-nowrap sm:text-base sm:ml-[10px]">Наличными курьеру</p>
        </div>
        <div className="flex items-center justify-center bg-[#F8F8F8] py-[18px] px-2 w-full rounded mx-[36px] lg:mx-0 lg:mb-3 sm:justify-start">
          <CardIconComponent size={26} className="flex-none" />
          <p className="text-lg ml-[20px] font-medium whitespace-nowrap sm:text-base sm:ml-[10px]">
            Пластиковой картой курьеру
          </p>
        </div>
        <div className="flex items-center justify-center bg-[#F8F8F8] py-[18px] px-2 w-full rounded sm:justify-start">
          <OnlineCardIcon size={26} className="flex-none" />
          <p className="text-lg ml-[20px] font-medium whitespace-nowrap sm:text-base sm:ml-[10px]">
            Пластиковой картой онлайн
          </p>
        </div>
      </div>

      <div className="text-lg mt-[30px]">
        <p>
          В процессе оформления покупки вам будет предложено привязать банковскую карту для оплаты заказа. Это удобно:
          привязав карту один раз, система запомнит ваши данные и повторный ввод реквизитов не потребуется.
        </p>
        <p className="mt-[20px]">
          Когда заказ будет собран, на вашей карте заблокируется необходимая сумма. Платёж спишется только после того,
          как вы получите заказ.
        </p>
        <p className="mt-[20px] text-orange-50 font-bold">
          Обращаем ваше внимание, что итоговая сумма заказа может измениться в процессе сборки из-за наличия весовых
          позиций или отсутствия товара.
        </p>
      </div>

      <p className="text-lg font-bold mt-[40px]">ОПЛАТА ЧЕРЕЗ ПАО «МОСКОВСКИЙ КРЕДИТНЫЙ БАНК»:</p>
      <p className="mt-[20px] text-lg">К оплате принимаются платежные карты: </p>

      <div className="mt-[40px] flex justify-content items-center">
        <VisaCardIcon size={100} />
        <MasternCardIcon size={100} />
        <MirCardIcon size={100} />
      </div>

      <div className="my-[40px] text-lg">
        <p>
          Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите Способ оплаты – онлайн.
          При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице ПАО «МОСКОВСКИЙ
          КРЕДИТНЫЙ БАНК», где Вам необходимо ввести данные Вашей банковской карты:{' '}
        </p>
        <ol className="mt-4 ml-4">
          <li>1. тип карты</li>
          <li>2. номер карты</li>
          <li>3. срок действия карты (указан на лицевой стороне карты)</li>
          <li>4. имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
          <li>5. CVC2/CVV2 код</li>
        </ol>
        <p className="mt-4">
          Далее нажать на кнопку <span className="font-bold">«ОПЛАТИТЬ»</span>
        </p>
      </div>
    </div>
    <Footer />
  </MainLayout>
);

export default PaymentPage;
